<template>
  <v-dialog v-model="visible" transition="dialog-top-transition" width="500">
    <v-card>
    <validation-observer ref="validationObs" v-slot="{ invalid }">
    <v-form @submit.prevent="inviteUser">
      <v-card-title class="text-h5 primary">
        <p style="color: #ffffff">Invite a New User</p>
      </v-card-title>
      <v-card-text>
        <p>
          Enter the name and email address of the new user to create. 
        </p>
        <!-- <v-text-field
            v-model="organisationId"
            label="organisation"
            dense
            outlined
            single-line
            hide-details
        ></v-text-field> -->
                <v-container class="py-0">
                  <v-row>
                    <v-col cols="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <v-text-field v-model="name" type="text" label="Name" />
                        <span class="warning--text">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <v-text-field
                          v-model="email"
                          type="email"
                          label="Email Address"
                        />
                        <span class="warning--text">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                    <p>
                    Or upload a CSV to invite users in bulk
                    </p>
                    <div class="d-flex">
                      <v-file-input
                        v-model="csv"
                        class="ml-2 white--text"
                        accept=".csv"
                        show-size
                        counter
                        label="Bulk User CSV"
                        @change="upload(csv)"
                      ></v-file-input>
                      <v-btn class="mb-4"
                        :disabled="recordCount==0" text @click="inviteAll">
                        <v-icon>mdi-account</v-icon>Invite {{recordCount}} Users
                      </v-btn>
                    </div>
                    </v-col>
                  </v-row>
                </v-container>
              
        
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mb-4"
            :disabled="invalid" 
            text 
            @click="inviteUser"> Invite </v-btn>
        <v-btn class="mb-4" text @click="closeDialog"> Close </v-btn>
      </v-card-actions>
      </v-form>
    </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";

const isRunningWithEmulator = !!(
  process.env.VUE_APP_USE_LOCAL_EMULATORS === "true" &&
  location.hostname === "localhost"
);

const EVENT_NAME = "close-invite-user-dialog";
const SHOW_MESSAGE_EVENT = "show-message";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: Boolean,
    organisationId: {
        type: String, 
        default: undefined
    },
  },
  data: () => ({
    email: null,
    name: null,
    csv: null,
    json: null,
    recordCount: 0
  }),
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit(EVENT_NAME);
      },
    },
  },
  methods: {
    closeDialog: function () {
      this.email = "";
      this.name = "";
      this.csv = null;
      this.json = null;
      this.recordCount = 0;
      this.$refs.validationObs.reset();
      this.$emit(EVENT_NAME);
    },
    upload(file) {
    	// if( file.type.match(/text\/csv/) || file.type.match(/vnd\.ms-excel/) ){
      if (file.type.match(/text\/csv/)){
	    	var oFReader = new FileReader();
	        oFReader.onloadend = (e) => {

            var lines = e.target.result.split("\n");
            var jsonresult = [];
            var headers = lines[0].trim().replace(/\r\n/g,"").split(",")
            var records = 0;
            for (var i=1; i<lines.length; i++){
              var obj = {};
              var currentline=lines[i].trim().replace(/\r\n/g,"").split(",");
              for (var j=0; j<headers.length; j++){
                obj[headers[j]] = currentline[j];
              }
              records++;
              jsonresult.push(obj);
            }
            
            // this.jsonResult = jsonresult
            this.json = JSON.stringify(jsonresult);
            this.recordCount = records;

	        	// var blob = new Blob([json], {type: 'application/json'});
            // console.log("OUTPUTING JSON as "+this.json)
            // console.log("count is "+records)
	        };
	        oFReader.readAsText(file);
        } else {
        	alert("This file does not seem to be a CSV.");
        }
    },
    inviteAll(){
      if (this.recordCount > 30) { 
        alert("Maximum 30 users can be imported at one time")
        return 
      }
      if (this.recordCount > 0) {
        var jsonObjects = JSON.parse(this.json)
        jsonObjects.forEach((element) => {
          // console.log(`Doing invite for ${element.Name}`)
          if (element.Name == null || element.Name === undefined || element.Name === "" ) {
            alert("Cannot invite user with no name")
          }
          if (element.Email == null || element.Email === undefined || element.Email === "" ) {
            alert("Cannot invite user without an email address")
          }
          this.name = element.Name
          this.email = element.Email
          this.inviteUser()
        });
        this.closeDialog();
      } else {
        alert("Error no users found to invite");
      }
    },
    async inviteUser() {
      const functionLocation = "australia-southeast1"; // us-central1, for example
      const functionRef = isRunningWithEmulator
        ? firebase // for emulator
            .app()
            .functions()
            .httpsCallable("createOrganisationUser")
        : firebase // for prod
            .app()
            .functions(functionLocation)
            .httpsCallable("createOrganisationUser");
      await functionRef({
        email: this.email,
        organisationId: this.organisationId,
        name: this.name,
        baseUrl: `${window.location.protocol}//${window.location.host}`
      })
      .then( (callResult) => {
        // alert(`Invitation Sent to ${JSON.stringify(callResult)}`)
        this.closeDialog();
        this.$emit(SHOW_MESSAGE_EVENT, `User with email ${callResult.data.email} was created`, "success");
      })
      .catch( (error) => { 
        // Getting the Error details.
        // var code = error.code;
        var message = error.message;
        // var details = error.details;
        // alert(`Error occured creating the user (${code}) - ${message}`)
        this.closeDialog();
        this.$emit(SHOW_MESSAGE_EVENT, `Error occured creating the user: ${message}`, "error");        
      });
    }
  },
};
</script>